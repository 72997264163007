.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 0;
    z-index: 1;
}:hover{
  animation: pulse 1s linear both;
  -webkit-animation: pulse 1s linear both;
}
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}
